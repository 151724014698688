import React from 'react';
import Layout from '../components/layout';
import Box, { BoxParagraph, BoxTitle, BoxIcon } from '../elements/box';
import Card, { CardImage } from '../elements/card';

const La_Supervision = () => (
  <Layout>
    <Box>
      <BoxTitle title="La Supervision" />
    </Box>
    <Box background="primary-light">
      <BoxParagraph>
        <p className="title is-5 is-family-secondary">OBJECTIF</p>
      </BoxParagraph>
      <BoxParagraph>
        <p><b>Soutenir la posture de l'accompagnement thérapeutique par une supervision centrée sur le patient, le psycho 
          praticien et les apports didactiques.</b></p>
      </BoxParagraph>
    </Box>
    <div className="columns">
      <div className="column">
        <Box height={true}>
          <BoxParagraph>
            <p className="title is-6 is-family-secondary">La situation du patient</p>
            <br />
            <p>Son histoire personelle, sa demande, son types de personnalité.</p>
            <p>Nous utiliserons une grille d'anamnèse permettant de recueillir au fur et à mesure des séances des informations 
              le concernant : deuils précoces, situations traumatiques, épisodes dépressifs, conduites addictives...</p>
          </BoxParagraph>
        </Box>
      </div>
      <div className="column is-1 is-hidden-mobile">
        <Box height={true} background="primary-light">
          <BoxIcon icon="fa-arrow-right"/>
        </Box>
      </div>
      <div className="column">
        <Box height={true}>
          <BoxParagraph>
            <p className="title is-6 is-family-secondary">Le psycho praticien</p>
            <br />
            <p>Qui est-il ? Quel est son cadre conceptuel ? Depuis combien de temps pratique-t-il ?<br />De quel type de soutien a t-il
              besoin ? Quels types de questions professionnelles se pose-t-il ? Quel est son rapport au cadre thérapeutique ?
              Comment s'accorde-t-il avec son patient? Quel type de posture utilise-t-il (intrapsychique, champ) ?</p>
          </BoxParagraph>
        </Box >
      </div>
    </div>
    <div className="columns is-hidden-mobile">
      <div className="column">
        <Box height={true} background="primary-light">
          <BoxIcon icon="fa-arrow-up"/>
        </Box>
      </div>
      <div className="column is-1"></div>
      <div className="column">
        <Box height={true} background="primary-light">
          <BoxIcon icon="fa-arrow-down"/>
        </Box>
      </div>
    </div>
    <div className="columns">
      <div className="column">
        <Box height={true}>
          <BoxParagraph>
            <p className="title is-6 is-family-secondary">La relation patient - psychopraticien</p>
            <br />
            <p>Nous analyserons les enjeux transférentiels et contre-transférentiels actifs dans la situation thérapeutique.<br />
              Implication contrôlée et dévoilement du thérapeute, processus corporels, dissonances et résonances.<br />
              Les questions de cadre, le début et la fin de la thérapie, l'argent, l'éthique.</p>
          </BoxParagraph>
        </Box>
      </div>
      <div className="column is-1 is-hidden-mobile">
        <Box height={true} background="primary-light">
          <BoxIcon icon="fa-arrow-left"/>
        </Box>
      </div>
      <div className="column">
        <Box height={true}>
          <BoxParagraph>
            <p className="title is-6 is-family-secondary">Outils</p>
            <br />
            <p>Gestalt-thérapie, approche systémique, constellations familiales, processus corporels, théorie de l'attachement, 
              hypothèses psychopathos.</p>
          </BoxParagraph>
        </Box>
      </div>
    </div>
    <Box background="primary-light">
      <div className="columns">
        <div className="column is-3">
        <Card>
            <CardImage src="ballon6" alt="formations" size="is-4by5" />
          </Card>
        </div>
        <div className="column">
          <BoxParagraph>
            <p className="title is-5 is-family-secondary">METHODOLOGIE</p>
          </BoxParagraph>
          <BoxParagraph>
            <p>Présentation de cas cliniques.</p>
            <p>Possibilité de mises en situation pour sentir et percevoir la situation thérapeutique.</p>
            <p>Participation des pairs dans leurs résonances et vécus des situations abordées.</p>
          </BoxParagraph>
          <div className="columns">
            <div className="column">
              <Box>
                <BoxParagraph>
                  <p><b>Groupe de 7 personnes en journée de 9h à 17h</b></p>
                </BoxParagraph>
              </Box>
            </div>
            <div className="column">
              <Box>
                <BoxParagraph>
                  <p><b>Groupe de 4 personnes en demi-journée de 9h à 13h</b></p>
                </BoxParagraph>
              </Box>
            </div>
          </div>
        </div>
        <div className="column is-3">
          <Card>
            <CardImage src="ballon5" alt="formations" size="is-4by5" />
          </Card>
        </div>
      </div>
    </Box>
  </Layout>
)

export default La_Supervision;
